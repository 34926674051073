import React from 'react';
import Layout from '../../components/layout-id'
import Head from '../../components/head'
import '../../styles/dokter-tania.css'
//import scrollTo from 'gatsby-plugin-smoothscroll';
import Carousel from 'react-elastic-carousel'
import { OutboundLink } from "gatsby-plugin-google-analytics"
import {graphql} from 'gatsby'

import { GatsbyImage } from "gatsby-plugin-image";

import {Row, Col} from 'react-bootstrap'
import GooglePlay from '../../images/google-store.png'
import TaniaBusy from '../../images/tania-busy-2.png'
import KalkulatorPupuk from '../../images/kalkulator-pupuk.png'
import ChartPemasukan from '../../images/chart-pemasukan.png'
import ChartPengeluaran from '../../images/chart-pengeluaran.png'
import DrTaniaLogo from "../../images/drtania-logo.png"
import FarmerLogo from "../../images/farmer-logo.png";
import CityLogo from "../../images/city-logo.png";
  
export const query = graphql`{
  GooglePlayImg: file(relativePath: {eq: "google-store.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  DrTaniaImg: file(relativePath: {eq: "dr-tania.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  AppMockupImg: file(relativePath: {eq: "app-mockup.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  Identifikasi: file(relativePath: {eq: "identifikasi.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  Tanya: file(relativePath: {eq: "tanya.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  Budidaya: file(relativePath: {eq: "budidaya.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  TaniaBusy: file(relativePath: {eq: "tania-busy-2.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  KalkulatorPupuk: file(relativePath: {eq: "kalkulator-pupuk.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  ChartPemasukan: file(relativePath: {eq: "chart-pemasukan.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  ChartPengeluaran: file(relativePath: {eq: "chart-pengeluaran.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  Kebun: file(relativePath: {eq: "kebun.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  DrTaniaLogo: file(relativePath: {eq: "drtania-logo.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  FarmerLogo: file(relativePath: {eq: "farmer-logo.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  CityLogo: file(relativePath: {eq: "city-logo.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
}
`

class DokterTania extends React.Component {
    constructor(props){
        super(props);
        this.listener = null;
        this.state = {
            status: "section1",
            jmlFarmer : 3718
        };
    }
    
    componentDidMount(){

        fetch('https://tania-user-service.azurewebsites.net/website/v2/countusers').then(response => {
            return response.json()
        }).then(json => {
            this.setState({jmlFarmer : json.payload.num_of_users});
        })
        
        this.listener = document.addEventListener("scroll", e => {
            var scrolled = document.scrollingElement.scrollTop;
            if (scrolled >= 400 && scrolled <= 1000){
                if (this.state.status !== "section2"){
                    this.setState({status : "section2"});
                }
            } else if (scrolled > 1000 && scrolled <= 1500){
                if (this.state.status !== "section3"){
                    this.setState({status : "section3"});
                }
            } else if (scrolled > 1500 && scrolled <= 2000){
                if (this.state.status !== "section4"){
                    this.setState({status : "section4"});
                }
            } else if (scrolled > 2000){
                if (this.state.status !== "section5"){
                    this.setState({status : "section5"});
                }
            } else {
                if (this.state.status !== "section1"){
                    this.setState({status : "section1"});
                }
            }
        });
    }

    componentDidUpdate(){
        document.removeEventListener("scroll", this.listener);
    }

    render(){
        return (
            <Layout>
                <Head title = "Dokter-Tania"/>
                <div id = "dt-main-container">   

                    <div id = 'dt-section-container'>

                        <div id = "dt-section-1">

                            <div className = "additional-circle circle-section-1-right" />

                            <Row>
                                <div className = "additional-circle circle-section-1-left" />
                                <Col md={6} id = "dt-section-1-col-1">
                                    <div id = "dt-sec-1-col-1-img-container">
                                        <div id = "tania-img">

                                            <GatsbyImage
                                                image={this.props.data.DrTaniaImg.childImageSharp.gatsbyImageData}
                                                alt = "DrTania-Img" />
                                            
                                        </div>
                                        
                                        <div id = "app-img">
                                            <GatsbyImage
                                                image={this.props.data.AppMockupImg.childImageSharp.gatsbyImageData}
                                                alt = "AppMockup-Img" />
                                        </div>                                
                                        <div className = "additional-circle circle-section-1-middle" />
                                    </div>
                                </Col>


                                <Col md={5} id = "dt-section-1-col-2">
        
                                    <div id = "dt-section-1-primary-title"> 
                                        Temui Tania
                                    </div>  

                                    <div id = "secondary-title">                       
                                        <div>Teman perawatan kesehatan tanaman Anda</div>
                                        <div>Solusi pertanian Anda</div>
                                        <div>Teman pertanian Anda</div>
                                    </div>

                                    <div id = "content">
                                        <p>
                                            Tanaman Anda terlihat tidak sehat? tanaman anda tidak tumbuh dengan baik? atau Anda hanya ingin bertani? 
                                            Tanyakan kepada Dokter Tania dan tingkatkan hasil panen Anda sekarang.
                                        </p>

                                        <p>
                                            Baik Anda penghobi, petani, atau tukang kebun, Tania akan membantu Anda menanam tanaman.
                                        </p>         

                                        <div id = "googleplay-img">

                                            <OutboundLink href = "https://drtania.page.link/download">
                                                <GatsbyImage
                                                    image={this.props.data.GooglePlayImg.childImageSharp.gatsbyImageData}
                                                    alt = "google-play-img" />
                                            </OutboundLink>
                                        </div> 

                                    </div>
                                </Col>

                            </Row>
                        </div>

                        <div id = "dt-section-2">
                                
                            <div id = "dt-section-2-header-container">
                                
                                <div className = "additional-circle circle-section-2-right" />

                                <div id = "section-2-title">
                                    Lebih lengkap, lebih mudah, lebih akurat
                                </div>

                                <div className = "additional-circle circle-section-2-left" />
                            </div>

                            <Row className="justify-content-md-center section-2-body">
                                
                                    <Col md>
                                        <div className="dt-section-2-content">
                                            <div className = "dt-section-2-body-img">
                                                <GatsbyImage
                                                    image={this.props.data.Identifikasi.childImageSharp.gatsbyImageData}
                                                    alt = "identifikasi-img" />
                                            </div>

                                            <div className = "dt-section-2-body-title">Identifikasi</div>
                                            <p>
                                                Identifikasi penyakit tanaman hanya dengan sekali foto. Tak perlu khawatir gagal panen
                                            </p>
                                        </div>
                                    </Col>

                                    <Col md>
                                        <div className="dt-section-2-content">
                                            <div className = "dt-section-2-body-img">
                                                <GatsbyImage
                                                    image={this.props.data.Tanya.childImageSharp.gatsbyImageData}
                                                    alt = "tanya-img" />
                                            </div>
                                            <div className = "dt-section-2-body-title">Tanya Ahli</div>
                                            <p>
                                                Ga perlu bingung saat ada masalah, tanya ke agronomist kami kapan pun di manapun
                                            </p>
                                        </div>
                                    </Col>

                                    <Col md>
                                        <div className="dt-section-2-content">
                                            <div className = "dt-section-2-body-img">
                                                <GatsbyImage
                                                    image={this.props.data.Budidaya.childImageSharp.gatsbyImageData}
                                                    alt = "budidaya-img" />
                                            </div>
                                            <div className = "dt-section-2-body-title">Budidaya</div>
                                            <p>
                                                Belajar membudidayakan puluhan komoditas, dan pelajari ratusan penyakit. Jadi petani super pintar dan untung
                                            </p>
                                        </div>
                                    </Col>
                                
                            </Row>

                            <div className = "additional-circle circle-section-2-middle" />
                        </div>

                        <div id = "dt-section-3">
                                
                                
                            <div className = "additional-circle circle-section-3-right" />
                        
                            <div className = "additional-circle circle-section-3-left" />

                            <div id = "dt-section-3-content-container">
                                <img className = "section-3-tania-busy-img" src = {TaniaBusy} alt = "tania-busy-img"/>
                                <div id = "dt-section-3-content">
                                    <div id = "dt-section-3-content-header">
                                        <img className = "calculator-pupuk-img" src = {KalkulatorPupuk} alt = "kalkulator-pupuk-img" width = "40px"/>
                                        <div id = "dt-section-3-content-title">Kalkulator Pupuk</div>
                                    </div>
                                    <div id = "dt-section-3-content-paragraph">
                                        Hitung penggunaan pupuk untuk lahan kamu. Pemakaian jadi lebih hemat dan akurat. 
                                        Pengeluaran kamu berkurang, dan untungnya jadi makin banyak
                                    </div>
                                </div>

                                <div id = "dt-section-3-chart-container">
                                    <img className = "chart" src = {ChartPemasukan} alt = "chart-pemasukan-img"/>
                                    <img className = "chart" src = {ChartPengeluaran} alt = "chart-pengeluaran-img"/>
                                </div>
                            </div>

                            <div className = "additional-circle circle-section-3-middle" />
                        </div>

                        <div id = "dt-section-4">
                                
                            <div className = "additional-circle circle-section-4-right" />
                            <div className = "additional-circle circle-section-4-left" />
                            <div id = "dt-section-4-main-container">
                                <div className = "garden-img" >
                                    <GatsbyImage
                                        image={this.props.data.Kebun.childImageSharp.gatsbyImageData}
                                        alt = "kebun-img" />
                                </div>
                                <div id = "dt-section-4-content-container">
                                    <div id = "dt-section-4-content-title">
                                        Membantu Petani Untuk Mengatasi Penyakit Tumbuhan
                                    </div>
                                    <p>
                                        Hingga 40% dari hasil global hilang karena hama & penyakit tanaman setiap tahun, 
                                        atau setara dengan $ 220 miliar. Ini merupakan ancaman bagi keamanan pangan 
                                        global dan beban ekonomi bagi petani di seluruh dunia.
                                    </p>

                                    <p>
                                        Dokter Tania membantu petani untuk mengidentifikasi dan mengelola penyakit, 
                                        terhubung dengan para ahli, dan akses ke bank pengetahuan tentang 
                                        budidaya dan praktik terbaik dalam Pertanian sehingga mereka dapat 
                                        lebih produktif dan mengurangi risiko.
                                    </p>
                                </div>
                            </div>
                            
                        </div>

                        <div id = "dt-section-5">
                            <div id = "dt-section-5-title">Ayo ikut komunitas yang berkembang ini</div>
                            <div id = "dt-section-5-body">
                                <div className = "dt-section-5-body-content">
                                    <img className = "content-logo" src = {FarmerLogo} alt = "farmer-logo"/>
                                    <div className = "content-total">
                                        {this.state.jmlFarmer}

                                    </div>
                                    <div className = "content">Petani</div>
                                </div>

                                <div className = "dt-section-5-body-content">
                                    <img className = "content-logo" src = {CityLogo} alt = "city-logo"/>
                                    <div className = "content-total">
                                        257
                                    </div>
                                    <div className = "content">Kota</div>
                                </div>

                            </div>

                            <div className = "dt-section-5-carousel">                            
                                <Carousel itemsToShow={2}>
                                    <div className = "s">
                                        <div className = "carousel-item-header">
                                            “Wah, aplikasi ini sangat membantu sih. Saya paling suka sama fitur 
                                            yang tanya ahli. Semenjak pake fitur tersebut, saya jadi makin 
                                            paham sama insektisida karena dijelaskan oleh dokter tania”
                                        </div>

                                        <div className = "carousel-item-footer">
                                            <div className = "profil-img"></div>
                                            <div className = "profil">
                                                <div className = "profil-name">Mudah Kuprianto</div>
                                                <div className = "profil-profession">Petani Konvensional</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className = "s">
                                        <div className = "carousel-item-header">
                                            “Aplikasinya membantu saya , Saya paling suka sama fitur tanya ahli. 
                                            Buat saya yang hobi menanam sayuran di rumah, jadi punya tempat untuk bertanya 
                                            seputar pertanian.”
                                        </div>

                                        <div className = "carousel-item-footer">
                                            <div className = "profil-img"></div>
                                            <div className = "profil">
                                                <div className = "profil-name">Fahri</div>
                                                <div className = "profil-profession">Hobbyist Pertanian</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className = "s">
                                        <div className = "carousel-item-header">
                                            “Saya suka sih sama aplikasinya. Paling suka sih sama fitur identifikasi 
                                            penyakit, ngebantu banget buat saya yang bekerja di bidang pertanian 
                                            kalo lagi males googling”
                                        </div>

                                        <div className = "carousel-item-footer">
                                            <div className = "profil-img"></div>
                                            <div className = "profil">
                                                <div className = "profil-name">Hikmaya</div>
                                                <div className = "profil-profession">Pekerja Pertanian</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className = "s">
                                        <div className = "carousel-item-header">
                                            “Saya suka banget sih sama aplikasi ini, terlebih fitur tanya jawab dan 
                                            artikel pertanian. Membantu banget buat saya yang masih awam soal dunia 
                                            pertanian tapi pengen mulai mencoba. Semoga terus berkembang ya”
                                        </div>

                                        <div className = "carousel-item-footer">
                                            <div className = "profil-img"></div>
                                            <div className = "profil">
                                                <div className = "profil-name">Fira</div>
                                                <div className = "profil-profession">Hobbyist Pertanian</div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel>
                            </div>

                            <div id = "dt-section-5-comment-container">
                                <div className = "comment-container-box-1">
                                    <div className = "s">
                                        <div className = "carousel-item-header">
                                            “Wah, aplikasi ini sangat membantu sih. Saya paling suka sama fitur 
                                            yang tanya ahli. Semenjak pake fitur tersebut, saya jadi makin 
                                            paham sama insektisida karena dijelaskan oleh dokter tania”
                                        </div>

                                        <div className = "carousel-item-footer">
                                            <div className = "profil-img"></div>
                                            <div className = "profil">
                                                <div className = "profil-name">Mudah Kuprianto</div>
                                                <div className = "profil-profession">Petani Konvensional</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className = "s">
                                        <div className = "carousel-item-header">
                                            “Saya suka sih sama aplikasinya. Paling suka sih sama fitur identifikasi 
                                            penyakit, ngebantu banget buat saya yang bekerja di bidang pertanian 
                                            kalo lagi males googling”
                                        </div>

                                        <div className = "carousel-item-footer">
                                            <div className = "profil-img"></div>
                                            <div className = "profil">
                                                <div className = "profil-name">Hikmaya</div>
                                                <div className = "profil-profession">Pekerja Pertanian</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className = "comment-container-box-2">

                                    <div className = "s">
                                        <div className = "carousel-item-header">
                                            “Saya suka banget sih sama aplikasi ini, terlebih fitur tanya jawab dan 
                                            artikel pertanian. Membantu banget buat saya yang masih awam soal dunia 
                                            pertanian tapi pengen mulai mencoba. Semoga terus berkembang ya”
                                        </div>

                                        <div className = "carousel-item-footer">
                                            <div className = "profil-img"></div>
                                            <div className = "profil">
                                                <div className = "profil-name">Fira</div>
                                                <div className = "profil-profession">Hobbyist Pertanian</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className = "s">
                                        
                                        <div className = "carousel-item-header">
                                            “Aplikasinya membantu saya , Saya paling suka sama fitur tanya ahli. 
                                            Buat saya yang hobi menanam sayuran di rumah, jadi punya tempat untuk bertanya 
                                            seputar pertanian.”
                                        </div>

                                        <div className = "carousel-item-footer">
                                            <div className = "profil-img"></div>
                                            <div className = "profil">
                                                <div className = "profil-name">Fahri</div>
                                                <div className = "profil-profession">Hobbyist Pertanian</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>

                            </div>


                                
                        </div>

                        <div id = "dt-section-6">
                                
                            <div className = "additional-circle circle-section-6-right" />
                            <div className = "additional-circle circle-section-6-left" />
                            <div id = "dt-section-6-main-container">
                                <img className = "dr-tania-logo" src = {DrTaniaLogo} alt = "dr-tania-logo"/>
                                <div className = "additional-circle circle-section-6-middle" />
                                <h4>Ready to grow more?</h4>
                                <OutboundLink href = "https://drtania.page.link/download">
                                    <img src = {GooglePlay} alt = "google-play-img" className = "googleplay-img" />
                                </OutboundLink>
                            </div>

                            <div className = "additional-circle circle-section-6-bottom" />
                            
                        </div>
                        

                    </div>

                    
                </div>
            </Layout>
        );
    }    
}

export default DokterTania;